.modal-content {
    position: inherit;
    display: flex;
    flex-direction: column;
    width:700px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    margin-left: -108px;
}
@media (max-width: 480px) {
    .modal-content {
        position: inherit;
        display: flex;
        flex-direction: column;
        width:100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 0.3rem;
        outline: 0;
        margin-left: 0px;
    }
  }
.modal-footer {
    
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0.75rem;
    padding-left:15px;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}