.error-div {
  padding: 1rem;
  margin-top: 2rem;
  width: 50%;
  position: relative;
  left: 25%;
  background-color: #ffdde1;
}

.local-credentials-div {
  padding: 1rem;
  margin-top: 2rem;
  width: 50%;
  position: relative;
  left: 25%;
  background-color: #ddfaff;
}

.error-div-padding-top {
  padding-top: 1rem;
}
